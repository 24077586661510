
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GakkenSamlSso extends Vue {
  private async created() {
    Vue.prototype.$loading.start()
    Vue.prototype.$gdlsCookiesV3.setV3(false)
    this.samlSso()
  }

  private async samlSso(): Promise<void> {
    try {
      const { data } = await Vue.prototype.$http.httpWithToken.get('/saml/sso')
      window.location.href = data
    } catch {
      // エラー処理
      alert('エラーが発生しました。')
      // ! 保護者ログインの場合を考慮する必要があるがひとまず生徒ログインに飛ばす
      window.location.href = '/student/login'
      throw 'Drill Error!'
    }
  }
}
